import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Button from "../elements/button"
import InlineAccordion from "./inline-accordion"
import RateTable from "./rate-table"

const ServiceDetails = ({
  service,
  setSelectedService,
  currentServices,
  setServices,
}) => {
  const [isActive, setIsActive] = useState(false)

  //UPDATE ACTIVE STATUS OF SERVICE
  useEffect(() => {
    let filteredArray = false
    if (currentServices?.[0]) {
      filteredArray = currentServices.filter(item => item.id === service.id)
      filteredArray = filteredArray.length > 0
    }
    setIsActive(filteredArray)
  }, [currentServices, service])

  const handleClick = e => {
    e.preventDefault()
    if (!isActive) {
      setServices(prevState => [...prevState, service])
    } else {
      setServices(prevState => [
        ...prevState.filter(item => item.id !== service.id),
      ])
    }
    setSelectedService()
  }

  return (
    <div className="c-service-details-container">
      <div className="c-service-details">
        {service?.ACF?.name && (
          <h2 className="c-service-details-heading h3">{service.ACF.name}</h2>
        )}
        {service?.ACF?.description && (
          <div
            className="m-service-details-description s-body-regular"
            dangerouslySetInnerHTML={{ __html: service.ACF.description }}
          />
        )}

        {service?.ACF?.times?.[0] && (
          <InlineAccordion
            heading={"Rates"}
            children={<RateTable rows={service.ACF.times} />}
            message={ "<p>Please note: This schedule of fees is correct as of 1 July 2022 and is subject to change at any time. All prices are GST exclusive. For a detailed pricing schedule, please see our <a href='https://www.kincare.com.au/aged-care/pricing/' target='_new'>pricing page</a></p>"}
          />
        )}

        {service?.ACF?.detailed_description && (
          <InlineAccordion
            heading={"Details"}
            children={
              <div
                className="s-body-regular"
                dangerouslySetInnerHTML={{
                  __html: service.ACF.detailed_description,
                }}
              />
            }
          />
        )}

        {/* Rates and Details accordions */}
        <div className="m-service-details-button-container">
          <Button
            text={isActive ? "Remove Service" : "Add Service"}
            handleClick={e => handleClick(e)}
            className={`m-service-details-button${isActive ? " -red" : ""}`}
          />
        </div>
      </div>
    </div>
  )
}

ServiceDetails.propTypes = {
  service: PropTypes.object,
  currentServices: PropTypes.array,
  setServices: PropTypes.func,
  setSelectedService: PropTypes.func,
  delayed: PropTypes.bool,
}

export default ServiceDetails
