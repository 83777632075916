/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { ProgressContext } from "../context/Progress"
import { SelectedServiceContext } from "../context/SelectedService"

const ServiceCard = ({ service }) => {
  //PROGRESS CONTEXT
  const { currentServices } = useContext(ProgressContext)
  const [services] = currentServices

  //SELECTED SERVICE
  const { activeService } = useContext(SelectedServiceContext)
  const setSelectedService = activeService[1]

  const [isActive, setIsActive] = useState(false)

  //UPDATE ACTIVE STATUS OF SERVICE
  useEffect(() => {
    let filteredArray = false
    if (services?.[0]) {
      filteredArray = services.filter(item => item.id === service.id)
      filteredArray = filteredArray.length > 0
    }
    setIsActive(filteredArray)
  }, [services])

  const handleClick = (e, service) => {
    e.preventDefault()
    setSelectedService(service)
  }

  //USE EFFECT ON SERVICE LIST UPDATE CHECK IF THIS IS ACTIVE

  return (
    <React.Fragment>
      <a
        href="#"
        className={`c-service-card toggle${isActive ? " -active" : ""}`}
        onClick={e => handleClick(e, service)}
      >
        {service?.ACF?.name}
      </a>
    </React.Fragment>
  )
}

ServiceCard.propTypes = {
  service: PropTypes.object,
}

export default ServiceCard
