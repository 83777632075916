import React, { useContext } from "react"
import ServiceCard from "../components/service-card"
import { WordpressContext } from "../context/Wordpress"

const ServiceList = () => {
  //GET ALL Services
  const { allServices } = useContext(WordpressContext)
  const [serviceList] = allServices

  //Seperate services by category

  const group = serviceList.reduce((acc, item) => {
    if (!acc[item.ACF?.category]) {
      acc[item.ACF?.category] = []
    }

    acc[item.ACF?.category].push(item)
    return acc
  }, {})

  return (
    <div className="m-service-list-container">
      <div className="m-service-list">
        <h3 className="m-service-list-sidebar-heading">Add more services</h3>
        {group &&
          //Seperate services into categories
          //UPDATE ACTIVE STATUS OF SERVICE

          Object.keys(group).map(key => {
            const keyText = `${key[0].toUpperCase()}${key.slice(1)}`
            return (
              <div key={key} className="m-service-list-categorized">
                <h3 className="m-service-list-category s-label">
                  {keyText.replaceAll("_", " ")}
                </h3>
                <div className="m-service-list-items">
                  {group[key]?.map((service, i) => {
                    return <ServiceCard service={service} key={i} />
                  })}
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default ServiceList
