import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Lottie from "react-lottie-player"
import animation from "../data/mail-animation.json"

const FormSubmit = ({
  formStatus,
  setFormStatus,
  setFormSubmit,
  setDisplayForm,
}) => {
  const handleClose = () => {
    setFormStatus("")
    setFormSubmit(false)
    setDisplayForm(false)
  }
  //Click outside function
  useEffect(() => {
    if (formStatus === "error" || formStatus === "success") {
      setTimeout(() => {
        handleClose()
      }, 3000)
    }
  }, [formStatus])

  let text
  if (formStatus === "pending") {
    text = "Submitting ..."
  } else if (formStatus === "error") {
    text = "Sorry, something has gone wrong with your submission"
  } else if (formStatus === "success") {
    text = "Your estimate has been sent"
  }
  return (
    <div className="c-loading-container">
      <div className="c-loading-overlay">
        <div className="c-loading">
          <Lottie
            loop
            animationData={animation}
            play
            className="c-loading-animation"
          />
          {text && <p className="c-loading-text h3">{text}</p>}
        </div>
      </div>
    </div>
  )
}

FormSubmit.propTypes = {
  formStatus: PropTypes.string,
  setFormStatus: PropTypes.func,
  setFormSubmit: PropTypes.func,
  setDisplayForm: PropTypes.func,
}

export default FormSubmit
