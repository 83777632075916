import React from "react"
import StepText from "../elements/step-text"
import PropTypes from "prop-types"
import ServiceAccordionList from "./service-accordion-list"
import Button from "../elements/button"

const ServiceEditor = ({ stepFour, setModalOpen, delayed = false }) => {
  const handleClick = e => {
    e.preventDefault()
    setModalOpen(true)
  }
  return (
    <div className="m-service-editor-container step-left">
      <div className="m-service-editor">
        <StepText heading={stepFour?.heading} text={stepFour?.intro} />
        <ServiceAccordionList delayed={delayed} />
        <Button
          text="Add more services"
          className="m-service-editor-button -secondary"
          handleClick={handleClick}
        />
        {stepFour.additionalText && (
          <p className="e-step-text-body subtitle-one">
            {stepFour.additionalText}
          </p>
        )}
      </div>
    </div>
  )
}

ServiceEditor.propTypes = {
  stepFour: PropTypes.object,
  setModalOpen: PropTypes.func,
}

export default ServiceEditor
