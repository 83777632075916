/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react"
import { Trash } from "../helpers/icons"
import PropTypes from "prop-types"
import { ProgressContext } from "../context/Progress"

const Remove = ({ id, delayed }) => {
  const { currentServices, calculatingServices } = useContext(ProgressContext)
  const [services, setServices] = currentServices
  const [calculating, setCalculating] = calculatingServices
  const handleClick = (e, id) => {
    e.preventDefault()
    setTimeout(
      () => {
        setCalculating(false)
        setServices(
          services.filter(function (service) {
            let filteredService
            if (service.id !== id) {
              filteredService = service
            }
            return filteredService
          })
        )
      },
      delayed ? 1000 : 0
    )
    setCalculating(delayed ? true : false)
  }

  return (
    id && (
      <a className="e-remove" href="#" onClick={e => handleClick(e, id)}>
        <Trash className="e-remove-icon" />
        <span className="e-remove-text">Remove</span>
      </a>
    )
  )
}
Remove.propTypes = {
  id: PropTypes.number,
}

export default Remove
