import React, { useState, createContext } from "react"
import PropTypes from "prop-types"

export const SelectedServiceContext = createContext()

export const ServiceProvider = ({ children }) => {
  const [selectedService, setSelectedService] = useState()

  return (
    <SelectedServiceContext.Provider
      value={{
        activeService: [selectedService, setSelectedService],
      }}
    >
      {children}
    </SelectedServiceContext.Provider>
  )
}

ServiceProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
