import React, { useEffect, useContext } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { WordpressContext } from "../context/Wordpress"
import { ProgressContext } from "../context/Progress"
import { ServiceProvider } from "../context/SelectedService"

import StepOne from "../steps/step-one"
import StepTwo from "../steps/step-two"
import StepThree from "../steps/step-three"
import StepFour from "../steps/step-four"
import StepFive from "../steps/step-five"
import Loading from "../components/loading"

function IndexPage() {
  //WORDPRESS CONTEXT
  const { loading, allServices, allLevels } = useContext(WordpressContext)
  const [serviceList] = allServices
  const [levelsList] = allLevels
  const [status, setStatus] = loading

  //PROGRESS CONTEXT
  const { currentStep, calculatingServices } = useContext(ProgressContext)
  const [step] = currentStep
  const [calculating] = calculatingServices

  //UPDATE STATUS OF LOADING STATE
  useEffect(() => {
    if (status !== "error" && status !== "complete") {
      if (serviceList && levelsList) {
        setStatus("complete")
      }
    }
  }, [serviceList, levelsList])

  return (
    <Layout className={`${step === 5 ? " -final" : ""}`}>
      <Seo title="KinCare Home Care Package Calculator" />
      {/* LOADER */}
      {status !== "complete" && (
        <Loading
          text={
            status === "loading" ? "Loading ..." : "Sorry, an error has occured"
          }
        />
      )}

      {/* Load for final ammendments */}
      {calculating && <Loading text="Updating estimate ..." />}

      {status === "complete" && (
        <React.Fragment>
          {step === 1 && <StepOne />}

          {step === 2 && <StepTwo />}

          {step === 3 && (
            <ServiceProvider>
              <StepThree />
            </ServiceProvider>
          )}

          {step === 4 && (
            <ServiceProvider>
              <StepFour />
            </ServiceProvider>
          )}

          {step === 5 && (
            <ServiceProvider>
              <StepFive />
            </ServiceProvider>
          )}
        </React.Fragment>
      )}

      <form
        name="ContactForm"
        action="/thank-you"
        data-netlify-honeypot="bot-field"
        data-netlify="true"
        netlify-honeypot="bot-field"
        hidden
      >
        <input type="text" placeholder="First Name" name="firstName" />
        <input type="text" placeholder="Last Name" name="lastName" />
        <input type="email" placeholder="Email" name="email" />
        <input type="tel" placeholder="Phone" name="phone" />
        <input type="hidden" name="estimateUrl" />
        <input type="checkbox" name="callme" />
        <input type="checkbox" name="marketing" />
        <textarea type="textarea" placeholder="Message" name="message" />
      </form>
    </Layout>
  )
}

export default IndexPage
