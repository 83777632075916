import React from "react"

const RateTable = ({ rows }) => {
  return (
    <table className="c-rate-table">
      {rows.map((row, i) => {
        return (
          <tr className="c-rate-table-row">
            <td className="c-rate-table-rate">{row.rate}</td>
            <td className="c-rate-table-title">{row.title}</td>
          </tr>
        )
      })}
    </table>
  )
}

export default RateTable
