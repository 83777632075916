/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"
import { ChevronDown } from "../helpers/icons"

const InlineAccordion = ({ heading, children, message=""}) => {
  const [toggleAccordion, setToggleAccordion] = useState(false)

  const handleClick = e => {
    e.preventDefault()
    setToggleAccordion(!toggleAccordion)
  }

  return (
    heading &&
    children && (
      <div className="c-inline-accordion-container">
        <div className="c-inline-accordion">
          <a
            onClick={handleClick}
            href="#"
            className={`c-inline-accordion-toggle${
              toggleAccordion ? " -active" : ""
            }`}
          >
            <span className="c-inline-accordion-heading h4">{heading}</span>
            <ChevronDown className="c-inline-accordion-icon" />
          </a>
          {toggleAccordion && (
            <div className="c-inline-accordion-content">{children}

              <div className="accordian-details"
                dangerouslySetInnerHTML={{
                  __html: message,
                }}
                >
              </div>
          </div>
          )}

        </div>
      </div>
    )
  )
}

export default InlineAccordion
