import React, { useState, useContext } from "react"
import { ProgressContext } from "../context/Progress"
import Checkbox from "../elements/checkbox"
import PropTypes from "prop-types"
import log from "../helpers/log"
import urlEncoder from "../helpers/url-encoder"
import { Tick } from "../helpers/icons"

const ContactForm = ({ stepFive, setFormStatus, setFormSubmit }) => {
  //PROGRESS CONTEXT
  const { currentServices, currentLevel } = useContext(ProgressContext)
  const [services] = currentServices
  const [level] = currentLevel

  // Form data
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [marketing, setMarketing] = useState(false)
  const [callme, setCallme] = useState(false)
  const [message, setMessage] = useState("")

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setFormStatus("pending")
    setFormSubmit(true)

    if (typeof window.gtag !== "undefined") {
      window.gtag("event", "click", {
        event_category: "email",
        event_label: "servicebuilder_step5_email",
      })
    }

    if (typeof window.fbq !== "undefined") {
      window.fbq("track", "Lead")
    }

    const encoded = await urlEncoder(services, level)
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": event.target.getAttribute("name"),
        firstName,
        lastName,
        email,
        phone,
        estimateUrl: encoded,
        callme: !callme, //this is reversed because the Salesforce field is et4ae5__HasOptedOutOfMobile__c so we want the opposite of "call me"
        marketing: marketing,
        message,
      }),
    })
      .then(result => {
        if (result?.ok) {
          setFormStatus("success")
        } else {
          setFormStatus("error")
        }
      })
      .catch(error => {
        setFormStatus("error")
        log.error(error)
      })
  }

  return (
    <div className="m-form-container">
      <div className="m-form-intro">
        {stepFive.formHeading && (
          <h3 className="m-form-heading">{stepFive.formHeading}</h3>
        )}
        {stepFive.formIntro && (
          <p className="m-form-intro-text s-body-regular">
            {stepFive.formIntro}
          </p>
        )}
      </div>
      <form
        className="m-form"
        id="m-form"
        name="ContactForm"
        method="POST"
        onSubmit={handleSubmit}
        data-netlify-honeypot="bot-field"
        data-netlify="true"
        data-netlify-recaptcha="true"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="ContactForm" />
        <input type="hidden" name="estimateUrl" value="A URL" />
        <label htmlFor="firstName">First Name*</label>
        <input
          type="text"
          placeholder="First Name"
          name="firstName"
          required
          id="firstName"
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
        />
        <label htmlFor="lastName">Last Name*</label>
        <input
          type="text"
          placeholder="Last Name"
          name="lastName"
          required
          id="lastName"
          value={lastName}
          onChange={e => setLastName(e.target.value)}
        />
        <label htmlFor="email">Email*</label>
        <input
          type="email"
          placeholder="Email"
          name="email"
          required
          id="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <label htmlFor="phone">Mobile*</label>
        <input
          type="tel"
          placeholder="**** *** ***"
          name="phone"
          required
          pattern="[0-9]{4}\s[0-9]{3}\s[0-9]{3}"
          id="phone"
          value={phone}
          onChange={e => setPhone(e.target.value)}
        />

        <div className="e-checkbox-container">
          <div className="e-checkbox-inner">
            <input
              name="callme"
              id="callme"
              type="checkbox"
              className="e-checkbox"
              value={callme}
              onChange={e => setCallme(e.target.checked)}
            />
            <Tick className="e-checkbox-tick" />
          </div>
          <label className="e-checkbox-label" htmlFor="callme">
            I would like someone to call me
          </label>
        </div>

        <div className="e-checkbox-container">
          <div className="e-checkbox-inner">
            <input
              name="marketing"
              id="marketing"
              type="checkbox"
              className="e-checkbox"
              value={marketing}
              onChange={e => setMarketing(e.target.checked)}
            />
            <Tick className="e-checkbox-tick" />
          </div>
          <label className="e-checkbox-label" htmlFor="marketing">
            I would like to receive marketing material
          </label>
        </div>

        <label htmlFor="message">Add a message</label>
        <textarea
          type="textarea"
          placeholder="Message"
          name="message"
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
        <input type="submit" name="submit" className="m-form-submit" />
        <div data-netlify-recaptcha="true"></div>
      </form>
    </div>
  )
}

ContactForm.propTypes = {
  stepFive: PropTypes.object,
  setSubmitting: PropTypes.func,
  setSubmitSuccess: PropTypes.func,
  setFormStatus: PropTypes.func,
  setFormSubmit: PropTypes.func,
}

export default ContactForm
