import React, { useContext } from "react"
import { ProgressContext } from "../context/Progress"
import SummaryRow from "../elements/summary-row"
import feesCost from "../helpers/fees-cost"
import servicesCost from "../helpers/services-cost"

const PackageSummary = () => {
  //SELECTED LEVEL + PACKAGES
  const { currentLevel, currentServices } = useContext(ProgressContext)
  const [level] = currentLevel
  const [services] = currentServices
  return (
    <div className="m-package-summary-container">
      <div className="m-package-summary">
        {/* SELECTED PACKAGE */}
        <h2 className="e-step-text-heading h4">Home Care Package Funding</h2>
        {level && (
          <div className="c-summary-table-container">
            <div className="c-summary-table">
              <SummaryRow
                heading={level.ACF?.name}
                cost={(level.ACF?.weekly_budget_pensioner * 4).toFixed(2)}
                frequency="Monthly"
              />
            </div>
          </div>
        )}
        {!level && (
          <p className="m-package-summary-empty s-body-bold">
            No package level has been selected
          </p>
        )}
        {/* PACKAGE TOTAL */}
        <h2 className="e-step-text-heading h4">
          Package Total (services+fees)
        </h2>
        {(level || services?.[0]) && (
          <div className="c-summary-table-container">
            <div className="c-summary-table">
              {services?.[0] && (
                <SummaryRow
                  heading="Services total"
                  cost={servicesCost(services)}
                  frequency="Monthly"
                  className="-desktop"
                />
              )}
              {level && (
                <SummaryRow
                  heading="Fees"
                  cost={feesCost(
                    level.ACF?.package_management_fee,
                    level.ACF?.care_management_fee
                  )}
                  frequency="Monthly"
                  className="-desktop"
                />
              )}
              <SummaryRow
                heading="Package Total (services + fees)"
                boldHeading={true}
                cost={(
                  +feesCost(
                    level.ACF?.package_management_fee,
                    level.ACF?.care_management_fee
                  ) + +servicesCost(services)
                ).toFixed(2)}
                frequency="Monthly"
              />
              <SummaryRow
                heading={
                  (
                    +feesCost(
                      level.ACF?.package_management_fee,
                      level.ACF?.care_management_fee
                    ) +
                    +servicesCost(services) -
                    level.ACF?.weekly_budget_pensioner * 4
                  ).toFixed(2) >= 0
                    ? "Out of pocket costs"
                    : "HCP funds remaining"
                }
                boldHeading={true}
                frequency="Monthly"
                cost={Math.abs(
                  (
                    +feesCost(
                      level.ACF?.package_management_fee,
                      level.ACF?.care_management_fee
                    ) +
                    +servicesCost(services) -
                    level.ACF?.weekly_budget_pensioner * 4
                  ).toFixed(2)
                )}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PackageSummary
