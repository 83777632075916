/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useContext, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { ProgressContext } from "../context/Progress"
import Button from "../elements/button"
import { ChevronDown } from "../helpers/icons"

const PackageAccordion = ({
  title,
  id,
  description,
  levelsList,
  unsure = false,
}) => {
  //PROGRESS CONTEXT
  const { currentLevel } = useContext(ProgressContext)
  const [level, setLevel] = currentLevel

  const [toggle, setToggle] = useState(false)

  // Accordion toggle function
  const handleToggle = e => {
    e.preventDefault()
    setToggle(!toggle)
  }

  const handleClick = (e, selectedID) => {
    e.preventDefault()
    let unsureVariable
    if (active) {
      setLevel()
    } else {
      const matchedLevel = levelsList.filter(level => level?.id === selectedID)
      if (unsure) {
        unsureVariable = !unsureSelected ? true : false
      } else {
        unsureVariable = false
      }
      setLevel({ ...matchedLevel?.[0], unsure: unsureVariable })
    }
  }

  //UNSURE FUNCTION
  const [unsureSelected, setUnsureSelected] = useState(
    level?.unsure ? true : false
  )
  useEffect(() => {
    if (level?.unsure) {
      setUnsureSelected(true)
    } else {
      setUnsureSelected(false)
    }
  }, [level])

  const active =
    (id === level?.id && !unsure && !unsureSelected) ||
    (unsure && unsureSelected)
      ? true
      : false

  return (
    <div
      className={`c-package-accordion-container${active ? " -selected" : ""}`}
    >
      <div className={`c-package-accordion${toggle ? " -active" : ""}`}>
        <a
          href="#"
          onClick={e => handleToggle(e)}
          className={`c-package-accordion-toggle toggle${
            toggle ? " -active" : ""
          }`}
        >
          {title}
          <ChevronDown className="c-package-accordion-icon" />
        </a>
        <div
          className={`c-package-accordion-content${toggle ? " -active" : ""}`}
        >
          {!unsure && description && (
            <div
              className="c-package-accordion-description s-body-regular"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
          {/* STATIC TEXT FOR NOT SURE VARIATION */}
          {unsure && description && (
            <div className="c-package-accordion-description s-body-regular">
              <p>{description}</p>
            </div>
          )}
          <Button
            text={active ? "Selected" : "Select"}
            className="c-package-accordion-button -s-pad"
            handleClick={e => handleClick(e, id)}
          />
        </div>
      </div>
    </div>
  )
}

PackageAccordion.propTypes = {
  title: PropTypes.string,
  id: PropTypes.number,
  description: PropTypes.string,
  levelsList: PropTypes.array,
  unsure: PropTypes.bool,
}

export default PackageAccordion
