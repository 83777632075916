import React from "react"
import CostItem from "../elements/cost-item"
import PropTypes from "prop-types"
import feesCost from "../helpers/fees-cost"
import staticData from "../data/static.json"

const singleFeeCost = cost => {
  let newCost = cost.replace("$", "")
  // Convert cost from weekly to monthly
  newCost = parseFloat(newCost * 4).toFixed(2)
  return newCost
}

const FeeCosts = ({ level }) => {
  return (
    <div className="m-costs-container">
      <div className="m-costs">
        <h3 className="m-costs-heading subtitle-two">Fees</h3>
        <div className="m-costs-list-container">
          <div className="m-costs-list">
            {level.ACF?.package_management_fee && (
              <CostItem
                text="Package management fee"
                cost={singleFeeCost(level.ACF.package_management_fee)}
                details={staticData?.stepFive?.additionalPackageManagementInfo}
              />
            )}
            {level.ACF?.care_management_fee && (
              <CostItem
                text="Care management fee"
                cost={singleFeeCost(level.ACF.care_management_fee)}
                details={staticData?.stepFive?.additionalCareManagementInfo}
              />
            )}
            {level.ACF?.basic_care_fee && (
              <CostItem
                text="Basic care fee"
                cost={singleFeeCost(level.ACF.basic_care_fee)}
                details={staticData?.stepFive?.additionalBasicCareInfo}
              />
            )}
            <CostItem
              text="Total fees"
              boldText={true}
              cost={feesCost(
                level.ACF.package_management_fee,
                level.ACF.care_management_fee,
                level.ACF.basic_care_fee
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

FeeCosts.propTypes = {
  level: PropTypes.object,
}

export default FeeCosts
