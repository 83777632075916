import React, { useState, useEffect, useContext } from "react"
import DesktopSidebar from "../modules/desktop-sidebar"
import staticData from "../data/static.json"
import SidebarModal from "../modules/sidebar-modal"
import ServiceEditor from "../modules/service-editor"
import ServiceList from "../modules/service-list"
import { ProgressContext } from "../context/Progress"
import { SelectedServiceContext } from "../context/SelectedService"
import ServiceDetails from "../components/service-details"

const StepFour = () => {
  //PROGRESS CONTEXT
  const { nextStatus, currentServices } = useContext(ProgressContext)
  const setNext = nextStatus[1]
  const [services, setServices] = currentServices

  //SELECTED SERVICE
  const { activeService } = useContext(SelectedServiceContext)
  const [selectedService, setSelectedService] = activeService

  //Modal Animation variable
  const [modalClosing, setModalClosing] = useState(false)

  //UPDATE PROGRESS OF STEP TO UPDATE FOOTER NAVIGATION
  useEffect(() => {
    let nextVariable = true

    if (services.length > 0) {
      services.forEach(service => {
        if (!service.hours || !service.frequency) {
          nextVariable = false
        }
      })
      if (nextVariable === true) {
        setNext({ visible: true, active: true })
      } else {
        setNext({ visible: true, active: false })
      }
    } else {
      setNext({ visible: true, active: false })
    }
  }, [services])

  // CLOSE MODAL FUNCTION
  const closeModal = () => {
    setModalOpen(false)
  }

  //CLOSE SERVICE DETAILS FUNCTION
  const closeServiceDetails = () => {
    setTimeout(() => {
      setSelectedService()
      setModalClosing(false)
    }, 400)
    setModalClosing(true)
  }

  const [modalOpen, setModalOpen] = useState(false)

  const stepFour = staticData.stepFour
  return (
    <React.Fragment>
      <div className="s-step-three-container step-container">
        <div className="s-step-three step-wrapper">
          <ServiceEditor stepFour={stepFour} setModalOpen={setModalOpen} />
          {stepFour?.sidebarText?.[0] && (
            <DesktopSidebar items={stepFour.sidebarText} />
          )}
        </div>
      </div>
      {modalOpen && (
        // Add handle close to sidebar modal as prop
        <SidebarModal handleClose={closeModal}>
          <ServiceList />
        </SidebarModal>
      )}
      {selectedService && (
        // Add handle close to sidebar modal as prop
        <SidebarModal handleClose={closeServiceDetails} closing={modalClosing}>
          <ServiceDetails
            setSelectedService={closeServiceDetails}
            service={selectedService}
            currentServices={services}
            setServices={setServices}
          />
        </SidebarModal>
      )}
    </React.Fragment>
  )
}

export default StepFour
