/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import SidebarInfo from "../components/sidebar-info"

const DesktopSidebar = ({ items }) => {
  return (
    items?.[0] && (
      <div className="m-desktop-sidebar-container step-right">
        <div className="m-desktop-sidebar">
          {items.map((item, i) => {
            return <SidebarInfo {...item} key={i} />
          })}
        </div>
      </div>
    )
  )
}

export default DesktopSidebar
