import React, { useContext } from "react"
import { WordpressContext } from "../context/Wordpress"
import PackageAccordion from "../components/package-accordion"

const PackageList = () => {
  //GET ALL PACKAGES
  const { allLevels } = useContext(WordpressContext)
  const [levelsList] = allLevels

  const levelTwo = levelsList.filter(level => level.slug.indexOf("2") > 0)?.[0]

  return (
    <div className="m-package-list-container">
      <div className="m-package-list">
        {levelsList?.[0] &&
          levelsList.map((level, i) => {
            return (
              <PackageAccordion
                title={level.ACF?.name}
                key={i}
                id={level.id}
                description={level.ACF?.description}
                levelsList={levelsList}
              />
            )
          })}
        <PackageAccordion
          title="I'm not sure"
          id={levelTwo?.id}
          description={
            "That's okay. We will assume you are approved for level 2 for now"
          }
          levelsList={levelsList}
          unsure={true}
        />
      </div>
    </div>
  )
}

export default PackageList
