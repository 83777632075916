import React from "react"
import PropTypes from "prop-types"

const StepText = ({ heading, text }) => (
  <React.Fragment>
    {heading && <h2 className="e-step-text-heading h1">{heading}</h2>}
    {text && (
      <div className="e-step-text-body subtitle-one">
        <p dangerouslySetInnerHTML={{ __html: text }}></p>
      </div>
    )}
  </React.Fragment>
)

StepText.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
}

export default StepText
