import React, { useEffect, useContext } from "react"
import DesktopSidebar from "../modules/desktop-sidebar"
import staticData from "../data/static.json"
import { ProgressContext } from "../context/Progress"
import LevelSelection from "../modules/level-selection"

const StepTwo = () => {
  //PROGRESS CONTEXT
  const { nextStatus, currentLevel } = useContext(ProgressContext)
  const setNext = nextStatus[1]
  const [level] = currentLevel

  //UPDATE PROGRESS OF STEP TO UPDATE FOOTER NAVIGATION
  useEffect(() => {
    if (level) {
      setNext({ visible: true, active: true })
    } else {
      setNext({ visible: true, active: false })
    }
  }, [level])

  const stepTwo = staticData.stepTwo
  return (
    <div className="s-step-two-container step-container">
      <div className="s-step-two step-wrapper">
        <LevelSelection stepTwo={stepTwo} />
        {stepTwo?.sidebarText?.[0] && (
          <DesktopSidebar items={stepTwo.sidebarText} />
        )}
      </div>
    </div>
  )
}

export default StepTwo
