const monthlyCost = (cost, hours, frequency) => {
  let costValue
  if (cost && hours && frequency) {
    // Round up to nearest 0.5
    hours = (Math.ceil(hours * 2) / 2).toFixed(1)
    if (frequency === "daily") {
      costValue = cost * (hours * 7 * 4)
    } else if (frequency === "weekly") {
      costValue = cost * (hours * 4)
    } else if (frequency === "fortnightly") {
      costValue = cost * hours * 2
    } else if (frequency === "monthly") {
      costValue = cost * hours
    }
  }
  if (costValue) {
    return parseFloat(costValue)
  } else {
    return parseFloat(0)
  }
}

export default monthlyCost
