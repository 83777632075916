import React, { useEffect, useState, useContext } from "react"
import DesktopSidebar from "../modules/desktop-sidebar"
import PackageApproval from "../modules/package-approval"
import staticData from "../data/static.json"
import { ProgressContext } from "../context/Progress"

const StepOne = () => {
  //PROGRESS CONTEXT
  const { nextStatus } = useContext(ProgressContext)
  const [next, setNext] = nextStatus

  const [stepComplete, setStepComplete] = useState(false)
  //UPDATE PROGRESS OF STEP TO UPDATE FOOTER NAVIGATION
  useEffect(() => {
    if (stepComplete) {
      setNext({ visible: true, active: true })
    }
  }, [stepComplete])

  const stepOne = staticData.stepOne
  return (
    <div className="s-step-one-container step-container">
      <div className="s-step-one step-wrapper">
        <PackageApproval setStepComplete={setStepComplete} />
        {stepOne?.sidebarText?.[0] && (
          <DesktopSidebar items={stepOne.sidebarText} />
        )}
      </div>
    </div>
  )
}

export default StepOne
