import React from "react"
import PropTypes from "prop-types"

const SidebarInfo = ({ heading, text }) => {
  return (
    <div className="m-sidebar-info-container">
      <div className="m-sidebar-info">
        {heading && (
          <h3 className="m-sidebar-info-heading subtitle-two">{heading}</h3>
        )}
        {text && <p className="m-sidebar-info-body caption">{text}</p>}
      </div>
    </div>
  )
}

SidebarInfo.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
}

export default SidebarInfo
