/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from "react"
import { ProgressContext } from "../context/Progress"
import Remove from "../elements/remove"
import Select from "../elements/select"
import { default as options } from "../helpers/frequency"

const frequencyConversion = frequency => {
  if (frequency === "daily") {
    return "day"
  } else if (frequency === "weekly") {
    return "week"
  } else if (frequency === "fortnightly") {
    return "fortight"
  } else if (frequency === "monthly") {
    return "month"
  } else {
    return ""
  }
}

const ServiceAccordion = ({ title, hours, frequency, index, id, delayed }) => {
  const { currentServices, calculatingServices } = useContext(ProgressContext)
  const [services, setServices] = currentServices
  const [calculating, setCalculating] = calculatingServices
  const [hoursValue, setHoursValue] = useState(hours)

  const updateHours = e => {
    const isDelayed = delayed && frequency
    setTimeout(
      () => {
        setCalculating(false)

        setServices(prevState => {
          prevState[index].hours = e.target.value
          return [...prevState]
        })
      },
      isDelayed ? 1000 : 0
    )
    setHoursValue(e.target.value)
    setCalculating(isDelayed ? true : false)
  }

  const updateFrequency = e => {
    const isDelayed = delayed && hours
    setTimeout(
      () => {
        setCalculating(false)

        setServices(prevState => {
          prevState[index].frequency = e.target.value
          return [...prevState]
        })
      },
      isDelayed ? 1000 : 0
    )
    setCalculating(isDelayed ? true : false)
  }

  const [toggle, setToggle] = useState(false)

  // Accordion toggle function
  const handleToggle = e => {
    e.preventDefault()
    setToggle(!toggle)
  }

  //add conditional for title and index
  return (
    <div className="c-service-accordion-container">
      <div className={`c-service-accordion${toggle ? " -active" : ""}`}>
        <a
          href="#"
          onClick={e => handleToggle(e)}
          className={`c-service-accordion-toggle toggle${
            toggle ? " -active" : ""
          }`}
        >
          {title}
          <span className="c-service-accordion-helper s-body-regular">
            {(!hours || !frequency) && "Add required hours"}
            {hours &&
              frequency &&
              `${hours} ${
                hours === "1" ? "hour" : "hours"
              } per ${frequencyConversion(frequency)}`}
          </span>
        </a>
        <div className={`c-service-accordion-ui${toggle ? " -active" : ""}`}>
          <Remove id={id} delayed={delayed && hours && frequency} />
          <div className="c-service-accordion-inputs">
            <div className="field -number">
              <label htmlFor="hours">Hours</label>
              <input
                autoComplete="off"
                type="number"
                placeholder="0"
                name="hours"
                step="1"
                min="0"
                value={hoursValue}
                onChange={e => updateHours(e)}
              />
            </div>
            <Select
              options={options}
              label="Frequency"
              placeholder="Select"
              defaultValue={frequency ? frequency : ""}
              id="frequency"
              value={frequency}
              handleChange={updateFrequency}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceAccordion
