/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"
import { Info } from "../helpers/icons"
import CostInfo from "./cost-info"

const CostItem = ({ heading, text, cost, boldText, details }) => {
  const [displayInfo, setDisplayInfo] = useState(false)
  const openInfo = e => {
    e.preventDefault()
    setDisplayInfo(true)
  }

  const closeInfo = e => {
    e.preventDefault()
    setDisplayInfo(false)
  }
  return (
    <div className="e-cost-item-container">
      <div className="e-cost-item">
        {heading && <h4 className="e-cost-item-heading h6">{heading}</h4>}
        <div className="e-cost-item-content">
          <React.Fragment>
            <div className="e-cost-item-text-wrapper">
              {text && (
                <p
                  className={`e-cost-item-text ${
                    boldText ? "h6" : "s-body-regular"
                  }`}
                >
                  {text}
                </p>
              )}
              {details && (
                <div className="e-cost-item-details">
                  <a href="#" onClick={openInfo} className="e-cost-item-hover">
                    <Info className="e-cost-item-icon" />
                  </a>
                  {displayInfo && (
                    <CostInfo text={details} handleClose={closeInfo} />
                  )}
                </div>
              )}
            </div>
            {cost && (
              <p className="e-cost-item-cost s-body-regular">{`$${cost
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</p>
            )}
          </React.Fragment>
        </div>
      </div>
    </div>
  )
}

export default CostItem
