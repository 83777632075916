import React from "react"
import CostList from "./cost-list"
import PackageSummary from "./package-summary"

const FullSummary = () => {
  return (
    <div className="m-full-summary">
      <h3 className="m-full-summary-heading">Package summary</h3>
      <PackageSummary />
      <CostList />
    </div>
  )
}

export default FullSummary
