import monthlyCost from "./monthly-cost"

const servicesCost = services => {
  let totalCost = 0
  if (services?.[0]) {
    services.forEach(service => {
      totalCost += monthlyCost(
        service?.ACF?.hourly_rate,
        service?.hours,
        service?.frequency
      )
    })
  }
  return totalCost.toFixed(2)
}

export default servicesCost
