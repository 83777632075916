import React, { useContext } from "react"
import { ProgressContext } from "../context/Progress"
import ServiceCosts from "../components/service-costs"
import FeeCosts from "../components/fee-costs"
const CostList = () => {
  //SELECTED LEVEL
  const { currentServices, currentLevel } = useContext(ProgressContext)
  const [services] = currentServices
  const [level] = currentLevel
  return (
    <div className="m-cost-list-container">
      <div className="m-cost-list">
        {services.length > 0 && <ServiceCosts services={services} />}
        {level && <FeeCosts level={level} />}
      </div>
    </div>
  )
}

export default CostList
