import Button from "../elements/button"
import React, {useContext } from "react"
import staticData from "../data/static.json"
import { ProgressContext } from "../context/Progress"
import urlEncoder from "../helpers/url-encoder"

const FinalCTA = ({ setDisplayForm }) => {

  const { currentServices, currentLevel } = useContext(ProgressContext)
  const [services] = currentServices
  const [level] = currentLevel

  const formOpen = e => {
    e.preventDefault()
    setDisplayForm(true)
  }

  const clickedContact = async e => {
    e.preventDefault()
    typeof window !== "undefined" && window.gtag("event", "click", {
      'event_category':'contact',
      'event_label': 'servicebuilder_step5_contact'
    });
    typeof window !== "undefined" && window.fbq('track', 'Contact');

    const encoded = await urlEncoder(services, level);


    setTimeout(() => {window.location.href = `https://www.kincare.com.au/contact-us/?estimate_url=${encodeURIComponent(encoded)}&lead_source=HCP%20Calculator&lead_origin=Website`},1000);
  }






  const stepFive = staticData.stepFive
  return (
    <div className="c-final-cta-container">
      <div className="c-final-cta">
        <div className="c-final-cta-wrapper">
          {stepFive.saveHeading && (
            <h3 className="c-final-cta-heading subtitle-two">
              {stepFive.saveHeading}
            </h3>
          )}
          {stepFive.saveText && (
            <p className="c-final-cta-text s-body-regular">
              {stepFive.saveText}
            </p>
          )}
          <Button
            text="Email summary"
            className="c-final-cta-button"
            handleClick={formOpen}
          />
        </div>
        <div className="c-final-cta-wrapper">
          {stepFive.contactHeading && (
            <h3 className="c-final-cta-heading subtitle-two">
              {stepFive.contactHeading}
            </h3>
          )}
          {stepFive.contactText && (
            <p className="c-final-cta-text s-body-regular">
              {stepFive.contactText}
            </p>
          )}
          <Button
            text="Contact us"
            className="c-final-cta-button -secondary -invert
            "
            target="_blank"
            rel="noreferrer"
            handleClick={clickedContact}
          />
        </div>
      </div>
    </div>
  )
}

export default FinalCTA
