// Fees are given as weekly but displayed as monthly
const feesCost = (costOne = 0, costTwo = 0, costThree = 0) => {
  let total = +costOne + +costTwo + +costThree
  if (total) {
    total = total * 4
  }
  return parseFloat(total).toFixed(2)
}

export default feesCost
