import React from "react"
import CostItem from "../elements/cost-item"
import monthlyCost from "../helpers/monthly-cost"
import hoursConversion from "../helpers/hours-conversion"
import servicesCost from "../helpers/services-cost"
import PropTypes from "prop-types"

const ServiceCosts = ({ services }) => {
  return (
    <div className="m-costs-container">
      <div className="m-costs">
        <h3 className="m-costs-heading subtitle-two">Your services</h3>
        <div className="m-costs-list-container">
          <div className="m-costs-list">
            {services?.[0] && (
              <React.Fragment>
                {services.map((service, i) => (
                  <CostItem
                    key={i}
                    heading={service.ACF?.name}
                    text={`${hoursConversion(
                      service.hours,
                      service.frequency
                    )} hours per month`}
                    cost={monthlyCost(
                      service.ACF?.hourly_rate,
                      service.hours,
                      service.frequency
                    ).toFixed(2)}
                  />
                ))}
                <CostItem
                  text="Total service costs"
                  boldText={true}
                  cost={servicesCost(services)}
                />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

ServiceCosts.propTypes = {
  services: PropTypes.array,
}

export default ServiceCosts
