import React from "react"
import StepText from "../elements/step-text"
import PropTypes from "prop-types"
import PackageList from "./package-list"

const LevelSelection = ({ stepTwo }) => {
  return (
    <div className="m-level-selection-container step-left">
      <div className="m-level-selection">
        <StepText heading={stepTwo?.heading} text={stepTwo?.intro} />
        <PackageList />
        {stepTwo.additionalText && (
          <p className="e-step-text-body subtitle-one">
            {stepTwo.additionalText}
          </p>
        )}
      </div>
    </div>
  )
}

LevelSelection.propTypes = {
  stepTwo: PropTypes.object,
}

export default LevelSelection
