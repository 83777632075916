/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react"
import { CloseIcon } from "../helpers/icons"
import PropTypes from "prop-types"

const SidebarModal = ({
  children,
  className,
  handleClose,
  closing = false,
}) => {
  const handleClick = e => {
    e.preventDefault()
    setModalClosing(true)
    setTimeout(() => {
      handleClose()
    }, 400)
  }

  const [modalIndex, setModalIndex] = useState(false)
  const [modalClosing, setModalClosing] = useState(false)

  //Click outside function
  useEffect(() => {
    const zindex =
      document.getElementsByClassName("m-sidebar-modal-container")?.length || 0
    setModalIndex(zindex + 6)
  }, [])

  //CLOSE ON CLICKOUTSIDE FUNCTION
  // const handleClickOutside = e => {
  //   const sidebarModal = document.getElementsByClassName(
  //     "m-sidebar-modal-container"
  //   )[0]
  //   const serviceCards = document.getElementsByClassName("c-service-card")
  //   const domArray = [sidebarModal, ...serviceCards]
  //   let canClose = true
  //   if (domArray?.[0]) {
  //     domArray.forEach(elem => {
  //       if (elem.contains(e.target)) {
  //         canClose = false
  //       }
  //     })
  //   }
  //   if (canClose) {
  //     handleClose()
  //   }
  // }

  // //Click outside function
  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside)
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside)
  //   }
  // }, [])

  return (
    <React.Fragment>
      <div
        style={{ zIndex: modalIndex }}
        className={`m-sidebar-modal-container${
          className ? " " + className : ""
        }${modalClosing || closing ? " -closing" : " -opening"}`}
      >
        <div className="m-sidebar-modal">
          <a
            href="#"
            className="m-sidebar-modal-close"
            onClick={e => handleClick(e)}
          >
            <CloseIcon className="m-sidebar-modal-icon" />
          </a>
          {children}
        </div>
      </div>
      {modalIndex === 7 && (
        <div
          onClick={e => handleClick(e)}
          className={`transparent-layer${
            modalClosing || closing ? " -closing" : " -opening"
          }`}
        ></div>
      )}
    </React.Fragment>
  )
}

SidebarModal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  handleClose: PropTypes.func,
}

export default SidebarModal
