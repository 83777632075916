import React from "react"
import PropTypes from "prop-types"

const SummaryRow = ({
  heading,
  boldHeading = false,
  cost,
  frequency,
  className,
}) => {
  return (
    <div
      className={`e-summary-row-container${className ? " " + className : ""}`}
    >
      <div className="e-summary-row">
        {heading && (
          <h3
            className={`e-summary-row-heading ${
              boldHeading ? "l-body-bold" : "l-body-regular"
            }`}
          >
            {heading}
          </h3>
        )}
        <div className="e-summary-row-details">
          {cost && (
            <p className="e-summary-row-cost l-body-bold">
              {`$${cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
            </p>
          )}
          {frequency && (
            <p className="e-summary-row-frequency l-body-regular">
              {frequency}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

SummaryRow.propTypes = {
  heading: PropTypes.string,
  cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  frequency: PropTypes.string,
  boldHeading: PropTypes.bool,
  className: PropTypes.string,
}

export default SummaryRow
