const hoursConversion = (hours, frequency) => {
  let time
  if (hours && frequency) {
    if (frequency === "daily") {
      time = hours * 7 * 4
    } else if (frequency === "weekly") {
      time = hours * 4
    } else if (frequency === "fortnightly") {
      time = hours * 2
    } else if (frequency === "monthly") {
      time = hours
    }
    // Round up to nearest 0.5
    time = (Math.ceil(time * 2) / 2).toFixed(1)
  }

  if (time > 0) {
    //Return value to 1 decimal place if it is not a whole number
    return time % 1 !== 0 ? parseFloat(time).toFixed(1) : parseFloat(time)
  } else {
    return parseFloat(0)
  }
}

export default hoursConversion
