import React, { useContext, useState } from "react"
import staticData from "../data/static.json"
import { ProgressContext } from "../context/Progress"
import { WordpressContext } from "../context/Wordpress"
import PropTypes from "prop-types"
import Button from "../elements/button"
import StepText from "../elements/step-text"

const PackageApproval = ({ setStepComplete }) => {
  //LOCAL STATE
  const [yesStatus, setYesStatus] = useState(false)
  const [noStatus, setNoStatus] = useState(false)

  //WORDPRESS CONTEXT
  const { allLevels } = useContext(WordpressContext)
  const [levelsList] = allLevels

  //PROGRESS CONTEXT
  const { currentLevel } = useContext(ProgressContext)
  const [level, setLevel] = currentLevel

  const yesHandle = e => {
    e.preventDefault()
    setLevel()
    //Update footer paginations
    setStepComplete(true)
    //Update buttons active state
    setYesStatus(true)
    setNoStatus(false)
  }

  const noHandle = e => {
    e.preventDefault()
    //Selects Package 2
    const levelTwo = levelsList.filter(
      level => level.slug.indexOf("2") > 0
    )?.[0]
    levelTwo.noPackage = true
    if (!levelTwo) {
      console.warn("Package Level 2 not found")
    } else {
      setLevel(levelTwo)
    }
    //Update footer paginations
    setStepComplete(true)
    //Update buttons active state
    setYesStatus(false)
    setNoStatus(true)
  }

  const stepOne = staticData.stepOne
  return (
    <div className="m-package-approval-container step-left">
      <div className="m-package-approval">
        <StepText heading={stepOne.heading} text={stepOne.intro} />
        <div className="m-package-approval-buttons">
          <Button
            text="Yes"
            className={`-secondary m-package-approval-button${
              yesStatus ? " -active" : ""
            }`}
            handleClick={yesHandle}
          />
          <Button
            text="No"
            className={`-secondary m-package-approval-button${
              noStatus ? " -active" : ""
            }`}
            handleClick={noHandle}
          />
        </div>
        {stepOne.additionalText && (
          <p className="e-step-text-body subtitle-one">
            {stepOne.additionalText}
          </p>
        )}

        {noStatus && stepOne.noPackageApproval && (
          <p className="e-step-text-body -warning subtitle-one">
            {stepOne.noPackageApproval}
          </p>
        )}
      </div>
    </div>
  )
}

PackageApproval.propTypes = {
  setStepComplete: PropTypes.func,
}

export default PackageApproval
