import React from "react"
import StepText from "../elements/step-text"
import PropTypes from "prop-types"
import ServiceList from "./service-list"

const ServiceSelection = ({ stepThree }) => {
  return (
    <div className="m-level-selection-container step-left">
      <div className="m-level-selection">
        <StepText heading={stepThree?.heading} text={stepThree?.intro} />
        <ServiceList />
        {stepThree.additionalText && (
          <p className="e-step-text-body subtitle-one">
            {stepThree.additionalText}
          </p>
        )}
      </div>
    </div>
  )
}

ServiceSelection.propTypes = {
  stepThree: PropTypes.object,
}

export default ServiceSelection
