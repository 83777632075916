import React from "react"
import PropTypes from "prop-types"
import StepText from "../elements/step-text"
import ServiceAccordionList from "./service-accordion-list"
import Button from "../elements/button"
import PackageSummary from "./package-summary"

const Summary = ({ stepFive, setModalOpen, setDisplaySummary }) => {
  const handleClick = e => {
    e.preventDefault()
    setModalOpen(true)
  }

  return (
    <div className="m-summary-container step-left">
      <div className="m-summary">
        <StepText heading={stepFive.heading} text={stepFive.intro} />
        <PackageSummary />
        <Button
          text="View detailed summary"
          className="m-summary-button -detailed-summary -secondary"
          handleClick={e => {
            e.preventDefault()
            setDisplaySummary(true)
          }}
        />
        {/* SERVICES */}
        <h2 className="m-summary-services-heading e-step-text-heading h4">
          Your services
        </h2>
        <ServiceAccordionList delayed />
        <Button
          text="Add more services"
          className="m-summary-button -secondary"
          handleClick={handleClick}
        />
      </div>
    </div>
  )
}

Summary.propTypes = {
  stepFive: PropTypes.object,
  setModalOpen: PropTypes.func,
  setDisplaySummary: PropTypes.func,
}

export default Summary
