import React, { useContext } from "react"
import { ProgressContext } from "../context/Progress"
import ServiceAccordion from "../components/service-accordion"
import EmptyServices from "../components/empty-services"

const ServiceAccordionList = ({ delayed = false }) => {
  //ALL SELECTED SERVICES
  const { currentServices } = useContext(ProgressContext)
  const [services] = currentServices
  return (
    <React.Fragment>
      {services?.[0] &&
        services.map((service, i) => {
          return (
            <ServiceAccordion
              {...service}
              index={i}
              key={i}
              delayed={delayed}
              title={service.title?.rendered}
            />
          )
        })}
      {/* EMPTY STATE; */}
      {!services?.[0] && (
        <EmptyServices text="Add services to calculate your Package Estimate" />
      )}
    </React.Fragment>
  )
}

export default ServiceAccordionList
