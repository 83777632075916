import React, { useState, useContext } from "react"
import { ProgressContext } from "../context/Progress"
import { SelectedServiceContext } from "../context/SelectedService"
import staticData from "../data/static.json"
import SidebarModal from "../modules/sidebar-modal"
import ServiceList from "../modules/service-list"
import Summary from "../modules/summary"
import FinalCTA from "../components/final-cta"
import CostList from "../modules/cost-list"
import FullSummary from "../modules/full-summary"
import ContactForm from "../modules/contact-form"
import ServiceDetails from "../components/service-details"
import FormSubmit from "../components/form-submit"

const StepFive = () => {
  //PROGRESS CONTEXT
  const { currentServices } = useContext(ProgressContext)
  const [services, setServices] = currentServices

  //SELECTED SERVICE
  const { activeService } = useContext(SelectedServiceContext)
  const [selectedService, setSelectedService] = activeService

  // CLOSE MODAL FUNCTION
  const closeModal = () => {
    setModalOpen(false)
  }

  //CLOSE SUMMARY FUNCTION
  const closeSummary = () => {
    setDisplaySummary(false)
  }

  //CLOSE SUMMARY FUNCTION
  const closeForm = () => {
    setDisplayForm(false)
  }

  //CLOSE SERVICE DETAILS FUNCTION
  const closeServiceDetails = () => {
    setTimeout(() => {
      setSelectedService()
      setServiceDetailClosing(false)
    }, 400)
    setServiceDetailClosing(true)
  }

  const [modalOpen, setModalOpen] = useState(false)

  const [displaySummary, setDisplaySummary] = useState(false)

  const [displayForm, setDisplayForm] = useState(false)

  const [formStatus, setFormStatus] = useState("")
  const [formSubmit, setFormSubmit] = useState(false)

  //Modal Animation variables
  const [serviceDetailClosing, setServiceDetailClosing] = useState(false)

  // LOAD WHEN SERVICES CHANGE
  // const handleServices

  const stepFive = staticData.stepFive
  return (
    <React.Fragment>
      <div className="s-step-five-container step-container">
        <div className="s-step-five step-wrapper">
          <Summary
            stepFive={stepFive}
            setModalOpen={setModalOpen}
            setDisplaySummary={setDisplaySummary}
          />
          <div className="step-right">
            <CostList />
            <FinalCTA setDisplayForm={setDisplayForm} />
          </div>
        </div>
      </div>
      {modalOpen && (
        // Add handle close to sidebar modal as prop
        <SidebarModal handleClose={closeModal}>
          <ServiceList />
        </SidebarModal>
      )}

      {displayForm && (
        // Add handle close to sidebar modal as prop
        <SidebarModal handleClose={closeForm} className="-form">
          <ContactForm
            stepFive={stepFive}
            setFormStatus={setFormStatus}
            setFormSubmit={setFormSubmit}
          />
        </SidebarModal>
      )}

      {displaySummary && (
        <SidebarModal handleClose={closeSummary} className="-summary">
          <FullSummary />
        </SidebarModal>
      )}

      {formSubmit && (
        <FormSubmit
          formStatus={formStatus}
          setFormStatus={setFormStatus}
          setFormSubmit={setFormSubmit}
          setDisplayForm={setDisplayForm}
        />
      )}

      {selectedService && (
        // Add handle close to sidebar modal as prop
        <SidebarModal
          handleClose={closeServiceDetails}
          closing={serviceDetailClosing}
        >
          <ServiceDetails
            setSelectedService={closeServiceDetails}
            service={selectedService}
            currentServices={services}
            setServices={setServices}
            delayed
          />
        </SidebarModal>
      )}
    </React.Fragment>
  )
}

export default StepFive
