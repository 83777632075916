/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react"
import Button from "./button"

const CostInfo = ({ text, handleClose }) => {
  //CLOSE ON CLICKOUTSIDE FUNCTION
  const handleClickOutside = e => {
    const currentElement = document.getElementsByClassName(
      "e-cost-info-container"
    )[0]
    if (currentElement && !currentElement.contains(e.target)) {
      handleClose(e)
    }
  }

  // //Click outside function
  useEffect(() => {
    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  return (
    <div className="e-cost-info-container">
      <div className="e-cost-info">
        {text && <p className="e-cost-info-text s-body-regular">{text}</p>}
        <Button
          text="Close"
          handleClick={handleClose}
          className="e-cost-info-close -secondary -borderless"
        />
      </div>
    </div>
  )
}

export default CostInfo
