import React from "react"
import { Practitioner } from "../helpers/icons"
import PropTypes from "prop-types"

const EmptyServices = ({ text }) => (
  <div className="c-empty-services-container">
    <div className="c-empty-services">
      <Practitioner className="c-empty-services-icon" />
      <p className="c-empty-services-text l-body-regular">{text}</p>
    </div>
  </div>
)

EmptyServices.propTypes = {
  text: PropTypes.string,
}

export default EmptyServices
