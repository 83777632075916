import React, { useEffect, useContext, useState } from "react"
import DesktopSidebar from "../modules/desktop-sidebar"
import staticData from "../data/static.json"
import { ProgressContext } from "../context/Progress"
import { SelectedServiceContext } from "../context/SelectedService"
import ServiceSelection from "../modules/service-selection"
import SidebarModal from "../modules/sidebar-modal"
import ServiceDetails from "../components/service-details"

const StepThree = () => {
  //PROGRESS CONTEXT
  const { nextStatus, currentServices } = useContext(ProgressContext)
  const setNext = nextStatus[1]
  const [services, setServices] = currentServices

  //SELECTED SERVICE
  const { activeService } = useContext(SelectedServiceContext)
  const [selectedService, setSelectedService] = activeService

  //Modal Animation variable
  const [modalClosing, setModalClosing] = useState(false)

  //UPDATE PROGRESS OF STEP TO UPDATE FOOTER NAVIGATION
  useEffect(() => {
    if (services.length > 0) {
      setNext({ visible: true, active: true })
    } else {
      setNext({ visible: true, active: false })
    }
  }, [services])

  //CLOSE MODAL FUNCTION
  const closeModal = () => {
    setTimeout(() => {
      setSelectedService()
      setModalClosing(false)
    }, 400)
    setModalClosing(true)
  }

  const stepThree = staticData.stepThree
  return (
    <React.Fragment>
      <div className="s-step-three-container step-container">
        <div className="s-step-three step-wrapper">
          <ServiceSelection stepThree={stepThree} />
          {stepThree?.sidebarText?.[0] && (
            <DesktopSidebar items={stepThree.sidebarText} />
          )}
        </div>
      </div>
      {selectedService && (
        // Add handle close to sidebar modal as prop
        <SidebarModal handleClose={closeModal} closing={modalClosing}>
          <ServiceDetails
            service={selectedService}
            setSelectedService={closeModal}
            currentServices={services}
            setServices={setServices}
          />
        </SidebarModal>
      )}
    </React.Fragment>
  )
}

export default StepThree
