import React, { useState } from "react"
import PropTypes from "prop-types"

const Select = ({
  options,
  label,
  id,
  placeholder,
  defaultValue = "",
  handleChange = "",
}) => {
  const [valueUpdate, setValueUpdate] = useState(false)

  const selectChange = e => {
    setValueUpdate(true)
    if (handleChange) {
      handleChange(e)
    }
  }

  return (
    <div className="field -select">
      <label htmlFor={id} className="label -hide">
        {label}
      </label>
      <div className="control">
        <div className="select">
          <select
            className={`select-field${
              valueUpdate || defaultValue !== "" ? " -changed" : ""
            }`}
            defaultValue={defaultValue}
            onChange={selectChange}
          >
            {placeholder && (
              <option value="" disabled>
                {placeholder}
              </option>
            )}
            {options &&
              options.map(option => (
                <option key={option.value} value={option.value}>
                  {option.title}
                </option>
              ))}
          </select>
        </div>
      </div>
    </div>
  )
}

Select.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  handleChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    })
  ),
}

export default Select
